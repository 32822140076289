<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body h-355px d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-select
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organizationIdErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-select>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-select
              v-model="formData.status_type_id"
              label="Action event"
              item-text="text"
              item-value="index"
              :items="serverData.status_types"
              clearable
              outlined
              dense
              :error-messages="statusTypeErrors"
              @input="$v.formData.status_type_id.$touch()"
              @blur="$v.formData.status_type_id.$touch()"
            ></v-select>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px">
            <v-text-field
              v-model="formData.label"
              label="Label"
              clearable
              outlined
              dense
              :error-messages="labelErrors"
              @input="$v.formData.label.$touch()"
              @blur="$v.formData.label.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px mt-4">
            <p class="font-size-lg font-weight-medium">Update in WMS</p>
            <v-radio-group v-model="formData.update_wms" row>
              <v-radio label="Yes" :value="true"></v-radio>
              <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px mt-4">
            <p class="font-size-lg font-weight-medium">Close Status</p>
            <v-radio-group v-model="formData.is_closed" row>
              <v-radio label="Yes" :value="true"></v-radio>
              <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <div class="col-12 col-sm-6 col-md-4 h-75px mt-4">
            <p class="font-size-lg font-weight-medium">
              Make it visible to client
            </p>
            <v-radio-group v-model="formData.visible_to_client" row>
              <v-radio label="Yes" :value="true"></v-radio>
              <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center mt-10"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  // components: { JSONEditor },
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      organization_id: { required },
      status_type_id: { required },
      label: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      organization_id: null,
      status_type_id: null,
      label: null,
      update_wms: false,
      is_closed: false,
      visible_to_client: false,
    },
    serverData: {
      organizations: [],
      status_types: [],
    },
  }),
  methods: {
    async loadDataFromServer() {
      this.pageLoader(true);
      const payload = {};
      let actionUrl = "create";
      if (this.actionType) {
        actionUrl = "edit";
        payload.id = this.$store.getters.getItemForAction.id;
      }
      await ApiService.post(`/order_management/statuses/${actionUrl}`, payload)
        .then((response) => {
          const { data } = response;
          this.serverData = data;
          if (this.actionType) {
            this.formData = data.status;
          }
        })
        .catch(() => {
          // this.dialog = false;
          this.pageLoader(false);
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        this.formData.id = this.$store.getters.getItemForAction.id;
      }
      this.pageLoader(true);
      ApiService.post(`/order_management/statuses/${actionUrl}`, this.formData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
          this.dialog = false;
          this.resetFormData();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        organization_id: null,
        status_type_id: null,
        label: null,
        update_wms: false,
        is_closed: false,
        visible_to_client: false,
      };
      this.serverData = {
        organizations: [],
        status_types: [],
      };
      this.$v.$reset();
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    organizationIdErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
    statusTypeErrors: function () {
      return this.handleFormValidation("status_type_id", this);
    },
    labelErrors: function () {
      return this.handleFormValidation("label", this);
    },
  },
};
</script>
